module.exports = [{
      plugin: require('/home/rudy/projects/imprimeurs-3d-974-v2/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/rudy/projects/imprimeurs-3d-974-v2/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750},
    },{
      plugin: require('/home/rudy/projects/imprimeurs-3d-974-v2/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/styles/typography"},
    },{
      plugin: require('/home/rudy/projects/imprimeurs-3d-974-v2/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"dontCacheBustUrlsMatching":{},"runtimeCaching":[{"urlPattern":{},"handler":"networkFirst"},{"urlPattern":{},"handler":"staleWhileRevalidate"},{"urlPattern":{},"handler":"staleWhileRevalidate"}]},
    },{
      plugin: require('/home/rudy/projects/imprimeurs-3d-974-v2/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
